import React from 'react';
import TrackVisibility from 'react-on-screen';
import Vinyls from './Vinyls';

function VinylsView({ VinylCollection }) {
  const sortedVinylCollection = [...VinylCollection].sort((a, b) => (a.title < b.title ? -1 : 1));

  return (
    <div className="row mt-30-reverse">
      {sortedVinylCollection.map((vinyl) => (
        <TrackVisibility once offset={200} className="col-lg-4 col-md-6 col-12 mt-30" key={vinyl.id}>
          <Vinyls content={vinyl} />
        </TrackVisibility>
      ))}
    </div>
  );
}

export default VinylsView;
