import React, { useState } from 'react';
import Sectiontitle from '../components/Sectiontitle';
import Layout from '../components/Layout';
import Pagination from '../components/Pagination';
import VinylsView from '../components/vinyl/VinylsView';
import { useGetVinylsQuery } from '../services/portfolioAPI';
import { IVinyl } from '../interfaces/vinyls.interface';

function Vinyls() {
  const [currentPage, setCurrentPage] = useState(1);
  const [vinylsPerPage] = useState(9);
  const { data: vinylsData, isLoading, isSuccess, error } = useGetVinylsQuery();
  if (!isLoading && isSuccess) {
    const vinyls: IVinyl[] = vinylsData as IVinyl[];
    const indexOfLastVinyls = currentPage * vinylsPerPage;
    const indexOfFirstVinyls = indexOfLastVinyls - vinylsPerPage;
    const currentVinyls = vinyls.slice(indexOfFirstVinyls, indexOfLastVinyls);

    const paginate = (e, pageNumber) => {
      e.preventDefault();
      setCurrentPage(pageNumber);
    };

    return (
      <Layout>
        <div className="mi-about mi-section mi-padding-top mi-padding-bottom">
          <div className="container">
            <Sectiontitle title="Vinyl Collection" />
            {<VinylsView VinylCollection={currentVinyls} />}
            {!(vinyls.length > vinylsPerPage) ? null : (
              <Pagination
                className="mt-50"
                itemsPerPage={vinylsPerPage}
                totalItems={vinyls.length}
                paginate={paginate}
                currentPage={currentPage}
              />
            )}
          </div>
        </div>
      </Layout>
    );
  }

  return <div>loading...</div>;
}

export default Vinyls;
