import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { IExperience, IInformation, ISkills, ITools } from '../interfaces/information.interface';
import { IService } from '../interfaces/services.interface';
import { IReview } from '../interfaces/reviews.interface';
import { IProject } from '../interfaces/projects.interface';
import { IVinyl } from '../interfaces/vinyls.interface';

const env = process.env.NODE_ENV;
let config;
console.log(env);

switch (env) {
  case 'production':
    config = require('../config/prod');
    break;

  case 'test':
    config = require('../config/beta');
    break;

  case 'development':
    config = require('../config/dev');
    break;

  default:
    config = require('../config/prod');
    break;
}

export const portfolioAPI = createApi({
  reducerPath: 'portfolioAPI',
  baseQuery: fetchBaseQuery({
    baseUrl: `${config.microservices.portfolioAPI}/api`,
  }),
  tagTypes: ['portfolio', 'information', 'services', 'reviews', 'skills', 'tools', 'experiences', 'vinyls'],
  endpoints: (builder) => ({
    getInformation: builder.query<IInformation, void>({
      query: () => '/Information/info',
      providesTags: ['information'],
    }),
    getReviews: builder.query<IReview[], void>({
      query: () => '/Reviews',
      providesTags: ['reviews'],
    }),
    getSkills: builder.query<ISkills[], void>({
      query: () => '/Information/skills',
      providesTags: ['skills'],
    }),
    getExperiences: builder.query<IExperience, void>({
      query: () => '/Information/experiences',
      providesTags: ['experiences'],
    }),
    getTools: builder.query<ITools[], void>({
      query: () => '/Information/tools',
      providesTags: ['tools'],
    }),
    getServices: builder.query<IService[], void>({
      query: () => '/Services',
      providesTags: ['services'],
    }),
    getProjects: builder.query<IProject[], void>({
      query: () => '/Portfolio',
      providesTags: ['portfolio'],
    }),
    getVinyls: builder.query<IVinyl[], void>({
      query: () => '/Vinyls',
      providesTags: ['vinyls'],
    }),
  }),
});

export const {
  useGetInformationQuery,
  useGetProjectsQuery,
  useGetExperiencesQuery,
  useGetReviewsQuery,
  useGetServicesQuery,
  useGetSkillsQuery,
  useGetToolsQuery,
  useGetVinylsQuery,
} = portfolioAPI;
