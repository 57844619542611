import React, { useState } from 'react';
import { NavLink, Link } from 'react-router-dom';
import LineIcon from 'react-lineicons';
import ParticlesEffects from './ParticlesEffects';
import Socialicons from './Socialicons';
import { useGetInformationQuery } from '../services/portfolioAPI';
import { useTheme } from '@material-ui/core';

function Header() {
  // const [information, setInformation] = useState("");
  const [navigationToggler, setNavigationToggler] = useState(false);
  const theme = useTheme();
  const { data: information, isLoading, isSuccess, isError, error } = useGetInformationQuery();

  const handleNavigationToggler = () => {
    setNavigationToggler(!navigationToggler);
  };

  const Divider = ({ children }) => {
    return (
      <div className="container">
        <div className="border" />
        <span className="content">{children}</span>
        <div className="border" />
      </div>
    );
  };

  if (!isLoading && isSuccess) {
    return (
      <nav className={navigationToggler ? 'mi-header is-visible' : 'mi-header'}>
        <ParticlesEffects class="mi-home-particle" opacity={0.5} linked={false} partValue={250} />
        <button onClick={handleNavigationToggler} className="mi-header-toggler">
          {!navigationToggler ? <LineIcon name="menu" /> : <LineIcon name="close" />}
        </button>
        <div className="mi-header-inner">
          <div className="mi-header-image">
            <Link to="/">
              <img src={information.brandImage} alt="brandimage" />
            </Link>
          </div>
          <ul className="mi-header-menu">
            <li>
              <NavLink to="/">
                <span>Home</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/about">
                <span>About</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/resume">
                <span>Background</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/portfolios">
                <span>Projects</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/contact">
                <span>Contact</span>
              </NavLink>
            </li>
            <Divider>Hobbies</Divider>
            <li>
              <NavLink to="/books">
                <span>Books</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/vinyls">
                <span>Vinyls</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/youtube">
                <span>YouTube</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/blog">
                <span>Blogs</span>
              </NavLink>
            </li>
          </ul>
          <Socialicons bordered socialLinks={information.socialLinks} />
          <p className="mi-header-copyright">
            &copy; {new Date().getFullYear()}{' '}
            <b>
              <a rel="noopener noreferrer" target="_blank" href="/">
                JPDesign
              </a>
            </b>
          </p>
        </div>
      </nav>
    );
  }
  return null;
}

export default Header;
