import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { IBook } from '../interfaces/notion.interface';
import { portfolioAPI } from './portfolioAPI';

const env = process.env.NODE_ENV;
let config;
console.log(env);

switch (env) {
  case 'production':
    config = require('../config/prod');
    break;

  case 'test':
    config = require('../config/beta');
    break;

  case 'development':
    config = require('../config/dev');
    break;

  default:
    config = require('../config/prod');
    break;
}
// `${config.microservices.notionAPI}/api/v1/notion`,
// baseQuery: fetchBaseQuery({
//   baseUrl: `${config.microservices.notionAPI}/api/v1/notion`,
// }),
// tagTypes: ['allbooks'],
export const notionAPI = portfolioAPI.injectEndpoints({
  endpoints: (builder) => ({
    getAllBooks: builder.query<IBook[], void>({
      query: () => `${config.microservices.notionAPI}/api/v1/notion/readingList`,
    }),
  }),
  overrideExisting: true,
});

export const { useGetAllBooksQuery } = notionAPI;
