import React, { useState } from 'react';
import Slider from 'react-slick';
import FsLightbox from 'fslightbox-react';
import * as Icon from 'react-feather';
import Sectiontitle from '../components/Sectiontitle';
import Layout from '../components/Layout';
import Service from '../components/Service';
import Testimonial from '../components/Testimonial';

import { useGetInformationQuery, useGetServicesQuery, useGetReviewsQuery } from '../services/portfolioAPI';
import { notionAPI, useGetAllBooksQuery } from '../services/notionAPI';
import { IInformation } from '../interfaces/information.interface';
import { IService } from '../interfaces/services.interface';
import { IReview } from '../interfaces/reviews.interface';
import { IBook } from '../interfaces/notion.interface';
import { ApiProvider } from '@reduxjs/toolkit/dist/query/react';

function About(): JSX.Element {
  const [toggler, setToggler] = useState(false);
  const { data: informationData, isLoading } = useGetInformationQuery();
  const { data: servicesData, isLoading: isLoadingServices } = useGetServicesQuery();
  const { data: reviewsData, isLoading: isLoadingReviews } = useGetReviewsQuery();
  const { data: allBooksData, isLoading: isLoadingBooks } = useGetAllBooksQuery();

  const sliderSettings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 6000,
    pauseOnHover: true,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleToggler = (event) => {
    setToggler(!toggler);
  };

  if (!isLoadingBooks) {
    const books: IBook[] = allBooksData as IBook[];
    // console.log(books);
  }

  if (!isLoadingServices && !isLoading && !isLoadingReviews) {
    const information: IInformation = informationData as IInformation;
    const services: IService[] = servicesData as IService[];
    const reviews: IReview[] = reviewsData as IReview[];
    // const books: IBook[] = allBooksData as IBook[];

    // console.log(books);

    return (
      <ApiProvider api={notionAPI}>
        <Layout>
          <div className="mi-about-area mi-section mi-padding-top">
            <div className="container">
              <Sectiontitle title="About Me" />
              <div className="row">
                <div className="col-lg-6">
                  <div className="mi-about-image">
                    <img src={information.aboutImage} alt="about" onClick={() => handleToggler(!toggler)} />
                    <span className="mi-about-image-icon">
                      <Icon.ZoomIn />
                    </span>
                    <FsLightbox toggler={toggler} sources={[information.aboutImageLg]} />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="mi-about-content">
                    <h3>
                      My name&apos;s <span className="color-theme">{information.name}</span>
                    </h3>
                    <p className="indent">
                      My name is Jeancarlos Perez and I am a graduate with a Master of Science in Computer Engineering
                      and a Staff Software Engineer. In my current role as a Staff Software Engineer, I have taken on
                      additional responsibilities such as mentoring junior team members, leading technical design
                      reviews, and contributing to the development of technical road maps. I have also been involved in
                      the full software development life-cycle, including requirements gathering, design,
                      implementation, testing, and deployment. <br></br> <br></br> Over the years I&apos;ve created a
                      large list of skill sets in database design and management, with experience in both relational and
                      non-relational database systems. This includes data modeling, data storage, and data retrieval
                      techniques, and have worked with a variety of database technologies such as MySQL, and NoSQL
                      solutions such as Google Firebase Firestore and Real-Time DB, GraphQL and Apollo. To use these
                      systems to the full potential, I have utilized modern web frameworks, embedded systems, and common
                      programming languages. Python, TypeScript, Java, C++, Node, Django and many many more. These
                      skills expand from the front-end to the back-end. Application Deployment with Cloud Computing is
                      my bread and butter; utilizing the Google Cloud Platform to implement a variety of solutions to
                      Host, Maintain, and Deploy Modern Web Applications from SASS companies to small hobby projects.
                    </p>
                    <ul>
                      {!information.name ? null : (
                        <li>
                          <b>Full Name</b> {information.name}
                        </li>
                      )}
                      {!information.age ? null : (
                        <li>
                          <b>Age</b> {information.age} Years
                        </li>
                      )}
                      {!information.phone ? null : (
                        <li>
                          <b>Phone</b> {information.phone}
                        </li>
                      )}
                      {!information.nationality ? null : (
                        <li>
                          <b>Nationality</b> {information.nationality}
                        </li>
                      )}
                      {!information.languages ? null : (
                        <li>
                          <b>Languages</b>{' '}
                          {information.languages.map((lang) => {
                            return ' ' + lang;
                          })}
                        </li>
                      )}
                      {!information.emails ? null : (
                        <li>
                          <b>Emails</b>{' '}
                          {information.emails.map((email) => {
                            return ' ' + email;
                          })}
                        </li>
                      )}
                      {!information.address ? null : (
                        <li>
                          <b>Address</b> {information.address}
                        </li>
                      )}
                      {!information.freelanceStatus ? null : (
                        <li>
                          <b>Freelance</b> {information.freelanceStatus}
                        </li>
                      )}
                    </ul>

                    <a href={information.cvfile} className="mi-button">
                      Download CV
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mi-service-area mi-section mi-padding-top">
            <div className="container">
              <Sectiontitle title="Services" />
              <div className="mi-service-wrapper">
                <div className="row mt-30-reverse">
                  {services.map((service) => (
                    <div className="col-lg-4 col-md-6 col-12 mt-30" key={service.title}>
                      <Service content={service} />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="mi-review-area mi-section mi-padding-top mi-padding-bottom">
            <div className="container">
              <Sectiontitle title="Reviews" />
              <div className="row justify-content-center">
                <div className="col-12">
                  <Slider className="mi-testimonial-slider" {...sliderSettings}>
                    {reviews.map((review) => (
                      <Testimonial key={review.id} content={review} />
                    ))}
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      </ApiProvider>
    );
  }

  return <div>Loading...</div>;
}

export default About;
