import React, { useState } from 'react';
import * as Icon from 'react-feather';
import FsLightbox from 'fslightbox-react';
// import PortfolioTags from './PortfolioTags';
import LineIcon from 'react-lineicons';
import { IVinyl, Status } from '../../interfaces/vinyls.interface';
import { styled, Tooltip, withStyles } from '@material-ui/core';

function Vinyls(props) {
  const [toggler, setToggler] = useState(false);
  const [hoverState, setHoverState] = useState(false);

  const vinyl: IVinyl = props.content;

  const handleToggler = (value) => {
    setToggler(value);
  };

  const toggleHoverState = () => {
    setHoverState(!hoverState);
  };

  const tooltipTitle = (statusName) => {
    if (statusName === Status.OWNED) {
      return 'This is a vinyl in my collection';
    } else if (statusName === Status.WANTED) {
      return 'This is a vinyl I want to own';
    } else if (statusName === Status.PREORDERED) {
      return 'This is a vinyl that has been preordered';
    } else {
      return 'This is a vinyl that I have not yet purchased';
    }
  };

  const statusStyle = () => {
    let style;
    if (hoverState) {
      style = {
        backgroundColor: stringToColour(vinyl.status?.color),
        opacity: 1,
        outline: stringToColour(vinyl.status?.color),
        outlineWidth: '2px',
        outlineStyle: 'solid',
      };
    } else {
      style = {
        backgroundColor: stringToColour(vinyl.status?.color),
        opacity: 0.7,
      };
    }

    return style;
  };

  const stringToColour = function (str) {
    let colour;
    if (str === 'red') {
      colour = 'rgb(191, 63, 63, 0.5)';
    } else if (str === 'green') {
      colour = 'rgb(63, 191, 63, 0.5)';
    } else {
      colour = 'rgb(191, 127, 63, 0.5)';
    }

    return colour;
  };

  const StatusTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} componentsProps={{ tooltip: { className: className } }} />
  ))({
    backgroundColor: 'red',
    opacity: 1,
  });

  return (
    <div className={props.isVisible ? 'mi-portfolio mi-portfolio-visible' : 'mi-portfolio'}>
      <Tooltip title={tooltipTitle(vinyl.status?.name)} placement="top">
        <a
          href={vinyl.resourceURL}
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: 'none', color: 'inherit' }}>
          <p
            style={statusStyle()}
            onMouseEnter={toggleHoverState}
            onMouseLeave={toggleHoverState}
            className={`mi-portfolio-${vinyl.status?.name}`}>
            {vinyl.status?.name}
          </p>
        </a>
      </Tooltip>
      <div className="mi-portfolio-vinyl-image">
        <img src={vinyl.coverImg} alt={vinyl.title} />
        {/* <a className="mi-portfolio-image-github" rel="noopener noreferrer" target="_blank" href={githubUrl}>
          <LineIcon name="github" />
        </a> */}
        <ul>
          {!vinyl.coverImg ? null : (
            <li>
              <button onClick={() => handleToggler(!toggler)}>
                <Icon.ZoomIn />
              </button>
            </li>
          )}
          {/* {url ? (
            <li>
              <a rel="noopener noreferrer" target="_blank" href={url}>
                <Icon.Link />
              </a>
            </li>
          ) : null} */}
        </ul>
      </div>
      {!vinyl.resourceURL ? (
        <h5>{vinyl.title}</h5>
      ) : (
        <h5>
          <a rel="noopener noreferrer" target="_blank" href={vinyl.resourceURL}>
            {vinyl.title}
          </a>
        </h5>
      )}
      {/* {subtitle ? <h6>{subtitle}</h6> : null}
      <h6>
        {tags ? (
          <p>
            {tags.map((tag) => (
              <PortfolioTags tag={tag} key={tag.toString()} />
            ))}
          </p>
        ) : null}{' '}
      </h6> */}
      {!vinyl.coverImg ? null : <FsLightbox toggler={toggler} sources={vinyl.coverImg} />}
    </div>
  );
}

export default Vinyls;
