import { configureStore } from '@reduxjs/toolkit';
import { persistStore } from 'redux-persist';
import { notionAPI } from '../services/notionAPI';
import { portfolioAPI } from '../services/portfolioAPI';

export const store = configureStore({
  reducer: {
    [portfolioAPI.reducerPath]: portfolioAPI.reducer,
    [notionAPI.reducerPath]: notionAPI.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(portfolioAPI.middleware, notionAPI.middleware),
});

export const persistor = persistStore(store);
