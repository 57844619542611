import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import 'babel-polyfill';
import 'whatwg-fetch';
import './App.scss';
import Home from './pages/Home';
import About from './pages/About';
import Resumes from './pages/Resumes';
import Portfolios from './pages/Portfolios';
import Contact from './pages/Contact';
import Notfound from './pages/Notfound';
import Header from './components/Header';
import BackgroundLines from './components/BackgroundLines';
import ParticlesEffects from './components/ParticlesEffects';
import Vinyls from './pages/Vinyls';

function App() {
  return (
    <BrowserRouter>
      <Header />
      <BackgroundLines />
      <ParticlesEffects class="mi-home-particle" opacity={0.6} linked={true} partValue={100} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/resume" element={<Resumes />} />
        <Route path="/portfolios" element={<Portfolios />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/vinyls" element={<Vinyls />} />
        <Route path="*" element={<Notfound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
