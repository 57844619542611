import React from 'react';
import Socialicons from '../components/Socialicons';
import Layout from '../components/Layout';
import ReactTypingEffect from 'react-typing-effect';
import { useGetInformationQuery } from '../services/portfolioAPI';
import Loading from 'react-loading';

function Home(): JSX.Element {
  const { data: information, isLoading, isSuccess, isError, error } = useGetInformationQuery();
  if (isSuccess) {
    const steps: (string | number)[] = [];
    information.aboutContents.forEach((content) => {
      steps.push(content, 5000);
    });

    if (!isLoading && isSuccess) {
      return (
        <Layout>
          <div className="mi-home-area mi-padding-section">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-10 col-12">
                  <div className="mi-home-content">
                    <img className="mi-home-image" src="./images/faceshotOne.jpg" alt="brandimage" />
                    <h1>
                      Welcome <br /> My Name is <span className="color-theme">{information.name}</span>
                    </h1>
                    <ReactTypingEffect
                      cursor="_"
                      text={steps}
                      speed={50}
                      eraseSpeed={50}
                      eraseDelay={1000}
                      typingDelay={50}
                    />
                    <Socialicons bordered socialLinks={information.socialLinks} />
                    <p></p>
                    <a href={information.cvfile} className="mi-button">
                      Download CV
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      );
    }
  } else {
    return <Loading></Loading>;
  }

  return <Loading></Loading>;
}

<Home />;

export default Home;
