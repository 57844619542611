const config = {
  port: 9898,

  winston: {
    info: {
      name: 'info-file',
      filename: 'logs/info.log',
      level: 'info',
    },
    error: {
      name: 'error-file',
      filename: 'logs/error.log',
      level: 'error',
    },
  },
  microservices: {
    portfolioAPI: 'http://localhost:9898',
    notionAPI: 'http://localhost:6060',
  },
};

module.exports = config;
