import React from 'react';
import Particles, { IParticlesParams } from 'react-particles-js';

function ParticlesEffects(props) {
  // constructor(props) {
  //     super(props);
  //     this.state = {
  //         opacity: props.opacity,
  //         enable: props.linked,
  //         class: props.class,
  //         partValue: props.partValue,
  //     };
  // }

  const paramConfig: IParticlesParams = {
    fps_limit: 28,
    particles: {
      number: {
        value: props.partValue,
        density: {
          enable: true,
          value_area: 2000,
        },
      },
      color: {
        value: '#ed725c',
      },
      opacity: {
        value: props.opacity,
      },
      size: {
        value: 6,
        random: true,
        anim: {
          speed: 3,
          size_min: 1,
        },
      },
      line_linked: {
        enable: props.enable,
      },
      move: {
        random: true,
        speed: 1.2,
        direction: 'bottom',
        out_mode: 'out',
      },
    },
  };

  return <Particles className={props.class} params={paramConfig} />;
}

export default ParticlesEffects;
