import React, { useState, useEffect } from 'react';
import axios from 'axios';
import TrackVisibility from 'react-on-screen';
import Sectiontitle from '../components/Sectiontitle';
import Layout from '../components/Layout';
import Progress from '../components/Progress';
import Resume from '../components/Resume';
import { DevicesOther, Build, Work, School } from '@material-ui/icons';
import { IWorkExperience, IEducationExperience } from '../interfaces/information.interface';
import { useGetExperiencesQuery, useGetSkillsQuery, useGetToolsQuery } from '../services/portfolioAPI';

function Resumes() {
  const { data: experiences, isLoading, isSuccess, isError, error } = useGetExperiencesQuery();
  const {
    data: skills,
    isLoading: skillsLoading,
    isSuccess: skillsSuccess,
    isError: skillsError,
  } = useGetSkillsQuery();
  const { data: tools, isLoading: toolsLoading, isSuccess: toolsSuccess, isError: toolsError } = useGetToolsQuery();
  if (!isLoading && !skillsLoading && !toolsLoading) {
    const workingExperience: IWorkExperience[] = experiences?.working as IWorkExperience[];
    const educationExperience: IEducationExperience[] = experiences?.education as IEducationExperience[];
    const sortedWorkExp = [...workingExperience].sort((a, b) => a.id - b.id);
    const sortedEducationExp = [...educationExperience].sort((a, b) => a.id - b.id);

    return (
      <Layout>
        <div className="mi-skills-area mi-section mi-padding-top">
          <div className="container">
            <Sectiontitle title="Skill & Tools" />
            <div className="mi-skills">
              <div className="mi-smalltitle">
                <span className="mi-smalltitle-icon">
                  <DevicesOther fontSize="large" />
                </span>
                <h4>Languages & Frameworks</h4>
              </div>
              <div className="row mt-30-reverse">
                {skills?.map((skill) => (
                  <TrackVisibility once className="col-lg-6 mt-30" key={skill.title}>
                    <Progress title={skill.title} icon={skill.icon} percentage={skill.value} variant="success" />
                  </TrackVisibility>
                ))}
              </div>
            </div>
            <p></p>
            <p></p>
            <div className="mi-skills">
              <div className="mi-smalltitle">
                <span className="mi-smalltitle-icon">
                  <Build fontSize="large" />
                </span>
                <h4>Development & Platform Tools</h4>
              </div>
              <div className="row mt-30-reverse">
                {tools?.map((tool) => (
                  <TrackVisibility once className="col-lg-6 mt-30" key={tool.title}>
                    <Progress title={tool.title} icon={tool.icon} percentage={tool.value} variant="info" />
                  </TrackVisibility>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="mi-resume-area mi-section mi-padding-top mi-padding-bottom">
          <div className="container">
            <Sectiontitle title="Background" />
            <div className="mi-smalltitle">
              <span className="mi-smalltitle-icon">
                <Work fontSize="large" />
              </span>
              <h4>Work Experience</h4>
            </div>
            <div className="mi-resume-wrapper">
              {sortedWorkExp.map((workingExp) => (
                <Resume key={workingExp.id} resumeData={workingExp} />
              ))}
            </div>
            <div className="mt-30"></div>
            <div className="mi-smalltitle">
              <span className="mi-smalltitle-icon">
                <School fontSize="large" />
              </span>
              <h4>Education</h4>
            </div>
            <div className="mi-resume-wrapper">
              {sortedEducationExp.map((educatonExp) => (
                <Resume key={educatonExp.id} resumeData={educatonExp} />
              ))}
            </div>
          </div>
        </div>
      </Layout>
    );
  }

  return <div>Loading...</div>;
}

export default Resumes;
