import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './store/portfolio.store';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './App';
import { portfolioAPI } from './services/portfolioAPI';
import { ApiProvider } from '@reduxjs/toolkit/dist/query/react';
const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ApiProvider api={portfolioAPI}>
        <App />
      </ApiProvider>
    </Provider>
  </React.StrictMode>
);
