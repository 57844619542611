const config = {
  port: 8080,

  winston: {
    info: {
      name: 'info-file',
      filename: 'logs/info.log',
      level: 'info',
    },
    error: {
      name: 'error-file',
      filename: 'logs/error.log',
      level: 'error',
    },
  },

  microservices: {
    portfolioAPI: 'https://personalportfolio-4caf3.uc.r.appspot.com',
    notionAPI: 'http://localhost:6060',
  },
};

module.exports = config;
