import React from 'react';
function Layout(props) {
  return (
    <div
      className="mi-wrapper"
      // className={darkMode ? "dark-mode mi-wrapper" : "light-mode mi-wrapper"}
    >
      {props.children}
    </div>
  );
}

export default Layout;
